//admin-main-wrapper
.admin-main-wrapper {
  display: flex;
  margin-top: 65px;

  @media (max-width: 767px) {
    margin-top: 1px;
  }
}

//admin-left-wrapper
.admin-left-wrapper {
  width: 260px;
  background-color: $white;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
  min-height: 100vh;
  height: 100%;
  position: fixed;
  top: 65px;
  left: 0;
  bottom: 0;
  padding-bottom: 75px;
  overflow-y: auto;

  ul {
    li {
      a {
        padding: 13px 10px 13px 31px;
        font-size: 14px;
        color: $black-base;
        display: flex;
        align-items: center;
        position: relative;

        i {
          margin-right: 12px;
          color: $grey-label;
        }

        &.active {
          background-color: $active-nav-back;

          &:before {
            content: "";
            background-color: $primary-color;
            height: 100%;
            width: 3px;
            position: absolute;
            left: 0;
          }

          i {
            color: $black-base;
          }
        }
      }
    }
  }
}

//admin-right-wrapper
.admin-right-wrapper {
  width: calc(100% - 260px);
  margin-left: 260px;
  position: relative;
}

//admin-right-header
.admin-right-header {
  height: 65px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: $white;
  display: flex;
  align-items: center;
  padding: 0 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

@media (max-width: 1250px) {
  .admin-left-wrapper {
    min-height: initial;
    height: initial;
    position: static;
  }

  .admin-right-wrapper {
    margin-left: 0;
    min-width: 990px;
  }

  .admin-main-wrapper {
    min-width: 1250px;
  }
}

//admin-heading
.admin-heading {
  font-size: 20px;
  font-weight: bold;
  color: $black;
  text-transform: uppercase;
}

//admin-right-header-bottom
.admin-right-header-bottom {
  height: 65px;
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  margin-top: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;

  .admin-right-header-bottom-right {
    display: flex;

    .btn.primary-btn {
      min-width: 120px;
      height: 40px;
      font-size: 14px;
      line-height: 0;
    }

    .custom-react-dropdown {
      margin-right: 10px;
      min-width: 140px;
    }
  }
}

//admin-right-data-wrapper
.admin-right-data-wrapper {
  padding: 30px 30px 0 30px;
}

//admin-table-wrapper
.admin-table-wrapper {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: $white;
  margin-bottom: 80px;
  width: 100%;
  overflow-x: auto;

  table {
    margin-bottom: 0;

    tr {
      height: 70px;
    }

    th,
    td {
      vertical-align: middle;
      padding: 0 21px;
      border-top: 1px solid $light-border;

      strong {
        font-weight: 600;
        cursor: pointer;
        color: $black-base;
      }
    }

    td {
      &.address-line {
        width: 480px;
      }
    }

    th {
      font-size: 13px;
      color: $black-base;
      font-weight: normal;
      border-bottom: 0;
    }

    td {
      font-size: 13px;
      color: $grey-label;

      .refund-text {
        color: $success;
        cursor: pointer;
        text-decoration: underline;

        &.disable {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    thead {
      th {
        border: none;
      }
    }

    tbody {
      th {
        font-weight: bold;
      }
    }

    a {
      color: $black-base;
    }
  }

  .table-heading-with-up-down {
    display: flex;
    align-items: center;

    .up-down-combine {
      margin-left: 4px;
    }
  }

  .edit-icon {
    margin-right: 7px;
    cursor: pointer;
  }

  .delete-icon,
  .cancel-icon {
    cursor: pointer;
  }

  .table-sponsor-logo {
    width: 75px;
    height: 45px;
    border-radius: 5px;
  }

  .table-partner-logo {
    width: 45px;
    height: 45px;
  }

  .table-amenity-logo {
    width: 75px;
    height: 45px;
    border-radius: 5px;
  }

  .table-amenity-main-logo {
    height: 36px;
    width: 36px;
  }
}

//pagination-wrapper
.pagination-wrapper {
  background-color: $white;
  border-top: 1px solid $light-border;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 0 21px;
  align-items: center;

  .pagination-text {
    font-size: 12px;
    color: $grey-label;
  }
}

//Admin-tab-view-data-wrapper
.admin-tab-view-data-wrapper {
  margin-top: 65px;
}

//Custom-tabs-wrapper
.custom-tabs-wrapper {
  &.nav-tabs {
    border: none;
    margin: 0 -7px;
    flex-wrap: nowrap;
  }

  .nav-link {
    width: 33.33%;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    color: $black;
    border-radius: 4px;
    background-color: $light-border;
    border-color: $light-border;
    padding: 22px 5px;
    position: relative;
    margin: 0 7px;

    &.active {
      background-color: $active-nav-back;
      border-color: $active-nav-back;
      color: $black;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $primary-color;
        bottom: -2px;
        left: 0;
        right: 0;
      }
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}

//Inner-tab-header-bottom
.inner-tab-header-bottom {
  height: 90px;
  margin-top: 0;
  box-shadow: none;
  border-bottom: 1px solid $primary-border;
}

//Inner-tab-table-wrapper
.inner-tab-table-wrapper {
  padding: 0;
}

//Admin-right-footer
.admin-right-footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $light-border;

  .admin-right-footer-text {
    font-size: 14px;
    color: $gray-97;
  }
}

//Admin-qr-data-wrapper
.admin-qr-data-wrapper {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: $white;
  margin-bottom: 80px;
  height: 840px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .admin-qr-data-inner-box {
    max-width: 512px;

    .qr-image {
      display: block;
      width: 512px;
      height: 512px;
    }

    .download-btn {
      font-size: 16px;
      font-weight: 400;
      color: $black-base;
      text-decoration: underline;
      padding: 0;
      height: auto;
      margin-top: 20px;
    }
  }
}

//Admin-right-detail-wrapper
.admin-right-detail-wrapper {
  margin: 95px 30px 80px 30px;
}

//Admin-right-detail-box
.admin-right-detail-box {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: $white;
  padding: 30px;
}

//Admin-detail-head-section
.admin-detail-head-section {
  .admin-detail-heading {
    font-size: 20px;
    font-weight: bold;
    color: $black-base;
    margin-bottom: 11px;
  }

  .admin-detail-desc {
    font-size: 12px;
    line-height: 1.67;
    color: $grey-label;
  }

  &.admin-detail-head-with-toggle {
    display: flex;
    justify-content: space-between;
  }
}

//Admin-detail-small-box
.admin-detail-small-box {
  h3 {
    font-size: 12px;
    color: $grey-label;
    margin-bottom: 9px;
  }

  p {
    font-size: 14px;
    color: $black-base;
    line-height: 1.5;

    &.active {
      color: $success;
    }

    &.inactive {
      color: $item-hover;
    }
  }
}

//Admin-detail-small-boxex-row
.admin-detail-small-boxex-row {
  display: flex;

  .admin-detail-small-box {
    width: 250px;
  }
}

//Admin-detail-tab-boxes-row
.admin-detail-tab-boxes-row {
  display: flex;

  .admin-detail-small-box {
    &:first-child {
      margin-right: 98px;
    }
  }
}

//Admin-detail-tab-box-list
.admin-detail-tab-box-list {
  ul {
    li {
      display: inline-block;
      border-radius: 2px;
      background-color: $list-item-back;
      font-size: 14px;
      color: $black-base;
      padding: 7px 8px;
      margin-right: 7px;
    }
  }
}

//Images-show-row
.images-show-row {
  display: flex;
  flex-wrap: wrap;

  img {
    width: 236px;
    height: 120px;
    border-radius: 4px;
    border: solid 1px $gray-97;
    margin-right: 20px;
    object-fit: contain;
  }
}

//Admin-detail-image-box
.admin-detail-image-box {
  margin-bottom: 0 !important;

  h3 {
    margin-bottom: 15px;
  }
}

//Right-detail-with-footer
.right-detail-with-footer {
  margin: 95px 30px 180px 30px;
}

//Admin-bottom-wrapper
.admin-bottom-wrapper {
  background-color: $white;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  height: 100px;
  border-radius: 4px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 30px;

  @media (max-width: 1250px) {
    position: static;
  }

  .admin-bottom-inner {
    width: calc(100% - 260px);
    display: flex;
    justify-content: space-between;

    @media (max-width: 1250px) {
      width: 100%;
    }

    .white-delete-btn {
      font-size: 13px;
      color: $red;

      &:hover {
        color: $red !important;
      }
    }

    .admin-bottom-right {
      display: flex;

      .edit-btn {
        margin-left: 20px;
      }
    }

    &.admin-bottom-align-right {
      justify-content: flex-end;
    }
  }

  .primary-main {
    min-width: 160px;
    height: 50px;

    &.btn-right-gap {
      margin-left: 20px;
    }
  }
}

//right-detail-show-box
.btlinzbox-right-detail-show-box {
  .admin-detail-head-section {
    margin-bottom: 33px;
  }

  .admin-detail-small-box {
    margin-bottom: 40px;
  }
}

//Add-detail-form-box
.add-detail-form-box {
  padding: 0;

  &.add-detail-form-box-gap {
    margin-bottom: 20px;
  }
}

//Add-detail-form-box-head
.add-detail-form-box-head {
  padding: 23px 30px;
  border-bottom: 1px solid $primary-border;

  .add-detail-heading {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
  }
}

//Add-detail-form-box-inner
.add-detail-form-box-inner {
  padding: 30px;

  .form-group {
    margin-bottom: 30px;

    label {
      top: 19px;
    }

    .floating-input {
      padding-bottom: 0;
    }
  }

  .form-control {
    height: 52px;
  }

  textarea.form-control {
    height: 120px;
    padding: 17px 18px;
  }

  &.checkboxes-bottom-gap {
    padding: 30px 30px 0 30px;
  }
}

//Upload-image-wrapper
.upload-image-wrapper {
  .upload-image-heading {
    font-size: 16px;
    font-weight: 500;
    color: $black-base;
    margin-bottom: 20px;
  }

  .upload-image-row {
    display: flex;
  }
}

//Edit-upload-image-row
.edit-upload-image-row {
  .images-show-row {
    img {
      margin-right: 0;
    }
  }

  .custom-upload {
    margin-right: 15px;
  }

  .image-with-delete-box {
    margin-right: 15px;
  }
}

//Image-with-delete-box
.image-with-delete-box {
  position: relative;

  .icon-cross {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $black;
    color: $white;
    font-size: 26px;
    cursor: pointer;
    border-radius: 0 4px 0 0;
  }
}

//Custom-checkbox-row
.custom-checkbox-row {
  display: flex;
  flex-wrap: wrap;

  .custom-checkbox {
    margin-bottom: 40px;
    width: 268px;
  }
}

//Detail-edit-form-box-inner
.detail-edit-form-box-inner {
  .form-group {
    label {
      top: -6px;
      background: $white;
    }
  }
}

//Upload-media-body
.upload-media-body {
  .upload-media-box {
    margin-right: 30px;
    position: relative;

    img {
      width: 150px;
      height: 150px;
      border-radius: 10px;
    }
  }

  .media {
    // align-items: center;
    height: 400px;
    overflow-y: auto;
    padding-top: 30px;
    grid-row: 1 / span 2;
    display: grid;
    grid-template-rows: 100px 300px;
  }

  .media-body {
    p {
      font-size: 13px;
      line-height: 1.54;
      color: $grey-label;
    }
  }

  .small-custom-upload {
    position: absolute;
    top: 37%;
    left: 0;
    right: 0;
    bottom: 0;

    .small-file-upload-label {
      margin: 0 auto;
    }
  }
}

//Small-custom-upload
.small-custom-upload {
  input {
    display: none;
  }

  .small-file-upload-label {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: $active-nav-back;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

//Add-sponsor-detail-box-inner
.add-sponsor-detail-box-inner {
  padding: 30px 30px 0 30px;

  .upload-media-body {
    margin-bottom: 53px;
  }
}

//Lock-form-group
.lock-form-group {
  .form-control {
    background-image: url("../../images/svg/lock.svg"); //../assets/images/svg/lock.svg
    background-repeat: no-repeat;
    background-position: 98.5% 50%;
  }
}

//Add-amenities-box
.add-amenities-box {
  .upload-media-body {
    margin-bottom: 30px;
  }

  &.add-amenities-with-close {
    .upload-media-body {
      margin-bottom: 43px;
    }
  }
}

//Add-amenities-input-box
.add-amenities-input-box {
  display: block;

  .form-group {
    width: 100%;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .add-amenities-input-icon {
    cursor: pointer;
  }
}

//Amenities-file-upload
.amenities-file-upload {
  input {
    display: none;
  }

  label {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    border: dashed 1px $file-upload-border;
    background-color: $file-upload-back;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      width: 82px !important;
      height: 82px !important;
    }
  }

  .icon-cross {
    position: absolute;
    top: -8px;
    right: -8px;
    color: $white;
    background-color: $black;
    height: 21px;
    width: 21px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    font-size: 24px;
    font-weight: 700;
    line-height: 21px;
    cursor: pointer;
  }
}

//Add-amenities-with-close
.add-amenities-with-close {
  padding-bottom: 30px;
  margin-bottom: 44px;
  border-bottom: 1px solid $primary-border;
}

//Amenities-image-show-box
.amenities-image-show-box {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  border: dashed 1px $file-upload-border;
  background-color: $file-upload-back;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: 82px !important;
    height: 82px !important;
  }

  .icon-cross {
    position: absolute;
    top: -8px;
    right: -8px;
    color: $white;
    background-color: $black;
    height: 21px;
    width: 21px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    font-size: 24px;
    font-weight: 700;
    line-height: 21px;
    cursor: pointer;
  }
}

//User-detail-box
.user-detail-box {
  display: flex;

  .user-detail-box-left {
    width: 155px;

    .user-detail-image-box {
      width: 120px;
      height: 120px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  .user-detail-box-right {
    width: calc(100% - 155px);
  }

  .user-info-top-right {
    display: flex;

    .btn {
      &:first-child {
        min-width: 153px;
        margin-right: 10px;
      }
    }
  }

  .primary-btn {
    font-size: 14px;
    min-width: 120px;
    height: 40px;
    margin-top: 0;
  }

  .user-info-head-section {
    margin-bottom: 34px;
  }

  .admin-detail-small-box {
    margin-bottom: 30px;
    min-width: 250px;
    width: 250px;
  }
}

//User-info-top-wrapper
.user-info-top-wrapper {
  display: flex;
  justify-content: space-between;

  .user-info-head-section {
    margin-top: 5px;
  }
}

//user-info-head-section
.user-info-head-section {
  .user-info-heading {
    font-size: 20px;
    font-weight: bold;
    color: $black-base;
    margin-bottom: 9px;
  }

  .user-info-text {
    font-size: 14px;
    color: $grey-label;
  }
}

//user-management-detail-detail-box
.user-management-detail-detail-box {
  padding: 30px 30px 0 30px;
  margin-bottom: 40px;
}

//User-management-detail-data-wrapper
.user-management-detail-data-wrapper {
  padding: 0;

  .user-management-detail-heading {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    color: $black-base;
    margin-bottom: 40px;
  }
}

//Admin-sponsor-detail-box
.admin-sponsor-detail-box {
  .admin-detail-small-boxex-row {
    .admin-detail-small-box {
      margin-bottom: 54px;
      width: 330px;
    }

    &.small-boxes-row-gap {
      .admin-detail-small-box {
        margin-bottom: 0;
      }
    }
  }

  .media-show-box {
    margin-bottom: 53px;
  }
}

//Media-show-box
.media-show-box {
  .media {
    align-items: center;
  }

  .media-body {
    margin-left: 30px;

    h3 {
      font-size: 20px;
      font-weight: bold;
      color: $black-base;
    }
  }

  img {
    width: 250px;
    height: 150px;
    border-radius: 10px;
  }
}

//Dashboard-detail-wrapper
.dashboard-detail-wrapper {
  display: flex;
  margin: 0 -12px;
}

//Col-dashboard-detail-box
.col-dashboard-detail-box {
  width: 33.33%;
  padding: 0 12px;
}

//dashboard-detail-box
.dashboard-detail-box {
  height: 120px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  cursor: pointer;

  .dashboard-detail-head-section {
    strong {
      font-size: 30px;
      font-weight: bold;
      color: $black-base;
      display: block;
      margin-bottom: 8px;
    }

    p {
      font-size: 14px;
      color: $grey-label;
    }
  }
}

//Refund-modal
.refund-modal {
  .radio-button-row {
    display: flex;
    margin-bottom: 35px;

    .custom-radio-gap {
      margin-right: 100px;
    }
  }
}

//User-detail-box-with-header
.user-detail-box-with-header {
  padding: 0;

  .user-detail-box {
    padding: 30px 30px 0 30px;
  }
}

//User-detail-box-header
.user-detail-box-header {
  height: 70px;
  padding: 0 20px 0 30px;
  border-bottom: 1px solid $primary-border;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 20px;
    color: $black-base;
    text-transform: uppercase;
    font-weight: bold;
  }

  .primary-btn {
    min-width: 140px;
    height: 40px;
    font-size: 14px;
    line-height: 0;
  }

  .user-detail-box-header-right {
    display: flex;
  }
}

//Right-detail-bottom-gap
.right-detail-bottom-gap {
  margin-bottom: 20px;
}

//Admin-right-payment-box
.admin-right-payment-box {
  .user-detail-box {
    display: block;
    padding: 30px;
  }

  .payment-info-row {
    &.payment-info-row-bottom-gap {
      margin-bottom: 34px;
    }

    p {
      width: 280px;

      span {
        display: block;
        font-weight: 700;
        margin-bottom: 8px;
      }
    }
  }

  .user-detail-box-footer {
    p {
      font-weight: bold;
    }
  }

  strong {
    display: flex !important;
    align-items: center;
  }
}

//Payment-info-row
.payment-info-row {
  display: flex;

  p {
    font-size: 14px;
    color: $black-base;
  }

  strong {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: $black-base;
  }
}

//User-detail-box-footer
.user-detail-box-footer {
  height: 80px;
  border-top: 1px solid $primary-border;
  display: flex;
  align-items: center;
  padding: 0 20px 0 30px;
}

//Text-with-images
.text-with-images {
  display: flex;
  align-items: center;
  margin-left: 23px;

  span {
    font-size: 14px;
    color: $green-light;
    text-decoration: underline;
    cursor: pointer;
  }
}

//Admin-right-dashboard-wrapper
.admin-right-dashboard-wrapper {
  .dashboard-detail-wrapper {
    margin-bottom: 20px;
  }

  .dashboard-table-wrapper {
    display: flex;
    margin: 0 -10px;

    .col-dashboard-table-box {
      width: 50%;
      padding: 0 10px;
    }

    .dashboard-table-box {
      .dashboard-table-box-head {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 21px;
        background-color: $white;
        border-bottom: 1px solid $light-border;

        h2 {
          font-size: 16px;
          font-weight: bold;
          color: $black-base;
        }

        span {
          font-size: 14px;
          font-weight: bold;
          color: $black-base;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

//Dashboard-graph-box
.dashboard-graph-box {
  .user-detail-box-header {
    padding: 0 21px;

    h3 {
      font-size: 16px;
      text-transform: capitalize;
    }

    .small-calendar-form-group {
      margin-left: 10px;
    }

    .graph-apply-btn {
      min-width: 80px;
      margin-right: 10px;
      margin-left: 10px;
    }

    .graph-export-btn {
      min-width: 120px;
    }

    .form-group {
      margin-bottom: 0;
    }

    .custom-dropdown-with-values {
      min-width: 200px;

      .css-yk16xz-control {
        height: 40px;
        min-height: 40px;
      }

      .css-1pahdxg-control {
        min-height: 40px;
        height: 40px;
        border: solid 1px #e6e6e6;

        &:hover {
          border: solid 1px #e6e6e6;
        }
      }

      .css-1hwfws3 {
        padding: 0 0 0 10px;
        height: 40px;
        min-height: 40px;
      }

      .css-1uccc91-singleValue {
        top: 13px;
      }

      .css-tlfecz-indicatorContainer {
        &:after {
          top: 8px;
          right: 3px;
        }
      }

      .css-1gtu0rj-indicatorContainer {
        &:after {
          top: 8px;
          right: 3px;
        }
      }
    }
  }

  .user-detail-box {
    padding: 30px 21px 30px 21px;

    @media (min-width: 1500px) {
      canvas {
        height: 600px !important;
      }
    }

    &.graph-detail-box {
      display: block;

      .graph-detail-middle-box {
        position: relative;

        .graph-detail-left-side {
          transform: rotate(-90deg);
          position: absolute;
          left: -79px;
          display: flex;
          top: 220px;
          font-size: 12px;
          color: #28313c;

          p {
            font-weight: bold;
          }

          &.graph-revenue-left-side {
            left: -16px;
          }
        }

        .graph-detail-right-side {
          margin-left: 40px;

          .graph-detail-footer {
            text-align: center;
            font-size: 12px;
            color: #28313c;
            margin-top: 31px;

            p {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

//small-calendar-form-group
.small-calendar-form-group {
  .form-control {
    height: 40px;
    width: 120px;
    padding: 10px;
    border-color: $primary-border;
    background-image: url("../../images/svg/calendar.svg");
    background-repeat: no-repeat;
    background-position: 97.5% 50%;
  }
}

//Graph-detail-box-header
.graph-detail-box-header {
  .primary-btn {
    margin-top: 0;
  }
}

//Booking-right-header-with-date
.booking-right-header-with-date {
  .small-calendar-form-group {
    margin-right: 10px;

    .form-control {
      min-width: 140px;
    }
  }
}

.css-26l3qy-menu {
  z-index: 2 !important;
}

//addeditconfigmodal
.addeditconfigmodal {
  overflow-x: auto !important;

  .modal-dialog {
    min-width: 1200px !important;
  }

  .string-value {
    word-break: break-word;
  }

  .custom-tabs-wrapper {
    .nav-link {
      width: 50%;
    }
  }
}

//Location Form Row
.location-form-row {
  display: flex;
  margin: 0 -7px;

  .form-group {
    width: 50%;
    padding: 0 7px;

    label {
      left: 24px;
    }
  }
}

//dashboard-empty-text-wrapper
.dashboard-empty-text-wrapper {
  position: absolute;
  left: 30px;
  right: 30px;
  width: 50%;
  font-weight: 400;

  .empty-text {
    position: absolute;
    text-align: center;
    left: 0px;
    right: 40px;
    top: 29px;
    font-size: 14px;
    color: #28313c;
  }

  &.dashboard-empty-text-wrapper-2 {
    left: 50%;
  }
}

//Graph-pointers-wrapper
.graph-pointers-wrapper {
  display: block;
  width: 100%;
  margin-left: 48px;
  margin-bottom: 45px;

  .graph-pointers-inner-wrapper {
    display: flex;
  }

  .graph-pointers-box {
    display: flex;

    .graph-pointer {
      width: 16px;
      height: 16px;
      border-radius: 2px;
      border: solid 1px #1665d8;
      background-color: rgba(22, 101, 216, 0.2);
      display: inline-block;
    }

    .graph-pointer-text {
      font-size: 13px;
      color: #4c4c4c;
      margin-left: 10px;
      margin-top: 2.3px;
      display: flex;
    }

    &.graph-pointers-box-second {
      margin-left: 50px;

      .graph-pointer {
        border: solid 1px #1fcb5f;
        background-color: rgba(31, 203, 95, 0.2);
      }
    }
  }
}

//add-partner-api-btn
.add-partner-api-btn {
  margin-top: 0 !important;
  margin-left: 10px;
}

.simple-text {
  display: none;

  &.green-text {
    color: green;
    margin-bottom: 10px;
  }

  &.red-text {
    color: red;
    margin-bottom: 10px;
  }
}

.addeditconfigmodal {
  .modal-body {
    padding: 20px 29px 12px !important;
  }
}

//User-detail-with-payment-btn
.user-detail-with-payment-btn {
  display: flex;
  justify-content: space-between;

  .btn.primary-btn {
    min-width: 170px;
    height: 40px;
    font-size: 14px;
    line-height: 0;
  }
}

tr {
  &:last-of-type {
    td {
      .dropdown-menu {
        top: -80px !important;
      }
    }
  }
}

.blintzbox-management-table-wrapper {
  td {
    &:last-child {
      white-space: nowrap;
    }
  }
}

// SEASONAL PROMO CODE
.seasonal-code-wrapper {
  padding: 30px;
  margin-top: 55px;
}
.outer-box-wrapper {
  padding: 20px 30px;
  min-height: 598px;
  background-color: #fff;
  .box-header {
    padding: 0 30px 20px;
    border-bottom: 1px solid #e6e6e6;
    margin: 0 -30px;
    h3 {
      font-size: 20px;
      font-weight: bold;
      color: #28313c;
    }
  }
  .react-datepicker-wrapper {
    input {
      width: 100%;
      height: 52px;
    }
  }
  .active-toggle {
    font-size: 14px;
    color: #1fa561;
    font-weight: normal;
  }
  .inactive-toggle {
    color: #646464;
    opacity: 0.8;
    font-size: 14px;
  }
}
.promo-form {
  margin-top: 34px;
  .form-group {
    margin-bottom: 30px;
  }
  .floating-input {
    padding-bottom: 0;
  }
  .action-btn {
    height: 50px;
    button {
      min-width: 160px;
      height: 100%;
      float: right;
    }
  }
}

.switchToggle input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}
.switchToggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 45px;
  max-width: 45px;
  height: 25px;
  background: #d1d1d1;
  display: block;
  border-radius: 100px;
  position: relative;
}
.switchToggle label:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 2px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}
.switchToggle input:checked + label,
.switchToggle input:checked + input + label {
  background: #1fa561;
}
.switchToggle input + label:before,
.switchToggle input + input + label:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 35px;
  width: 26px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}
.switchToggle input:checked + label:before,
.switchToggle input:checked + input + label:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 10px;
  width: 26px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}
.switchToggle input:checked + label:after,
.switchToggle input:checked + input + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}
.switchToggle label:active:after {
  width: 60px;
}
.toggle-switchArea {
  margin: 10px 0 10px 0;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
}
.box-header {
  p {
    font-size: 12px;
  }
}
.floating-input {
  &.label-heightlight {
    .form-control {
      &:focus {
        border: 1px solid #ffd938 !important;
      }
    }
  }
}
.floating-input {
  .form-control {
    &:focus {
      border: 1px solid #e1e1e1 !important;
    }
  }
}
