/* ==========================================================================
   fonts
  ========================================================================== */
@mixin font-shorthand($weight: $font-weight-base, $style:$font-style-base) {
  font-weight: $weight;
  font-style: $style;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-weight-style($weight: $font-weight-base, $style: $font-style-base) {
  font-family: 'Roboto', sans-serif;
  @include font-shorthand($weight, $style);
}

/* ==========================================================================
   mixing
  ========================================================================== */

@mixin box-shadow($top, $left, $blur, $color) {
  -webkit-box-shadow: $top $left $blur $color;
  -moz-box-shadow: $top $left $blur $color;
  box-shadow: $top $left $blur $color;
}

@mixin shadow() {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.02);
}

@mixin center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin rounded($radius: 4px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin round($radius: 2px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content
  }
  :-moz-placeholder {
    @content
  }
  ::-moz-placeholder {
    @content
  }
  :-ms-input-placeholder {
    @content
  }
}

@mixin padding($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}

@mixin margin($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}

@mixin head() {
  color: $black;
  text-transform: uppercase;
  font-size: $f-18;
  @include font-weight-style(500, normal);
}

@mixin wid100() {
  width: 100%;
  float: left;
}

@mixin truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin flex() {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin white-box() {
  @include rounded();
  background: $white;
  box-shadow: 0 8px 18px 0 rgba(54, 136, 217, 0.04);
}

@mixin blue-box() {
  padding: 20px;
  color: $primary-color;
  background: $primary-lighter;
}

@mixin round-btn() {
  border-radius: 20px !important;
}

@mixin p() {
  font-size: $f-14;
  color: $grey-dark;
}

@mixin link-btn() {
  text-decoration: underline;
  text-transform: none;
  padding: 0 !important;
}

@mixin img() {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

@mixin profile-pic() {
  width: 44px;
  height: 44px;
  border-radius: 4px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin small-txt() {
  font-size: $f-13;
  color: rgba($black-dark, 0.5);
  @include font-weight-style(500, normal);
}

@mixin dropdown-menu() {
  border: 0;
  border-radius: 0;
  margin-top: 15px;
  box-shadow: 0 8px 18px 0 rgba(54, 136, 217, 0.13);
  .dropdown-item {
    cursor: pointer;
    padding: 11px 25px;
    &:hover {
      color: $white;
      background-color: $primary-color;
    }
  }
}



  .round-user {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  img {
    @include img();
  }
  .user-short-name {
    font-size: $f-14;
    color: $white;
    @include font-weight-style(700, normal);
  }
}

.input-type {
  input[type=file] {
    opacity: 0;
    width: 100%;
    height: 100%;
    font-size: 0px;
    left: -20px;
    top: 0px;
    cursor: pointer;
    position: absolute;
    color: transparent;
  }
}