/* ==========================================================================
   term & condition
  ========================================================================== */
.content-wrapper{
  padding-top: 60px;
}

//Terms wrapper
.term-wrapper{
  padding: 60px 15px 15px 15px;
  .heading-wrap{
    margin-bottom: 0;
    h1{
      text-align: center;
      margin:30px 0;
      @media (max-width: 640px) {
        margin:20px 0;
      }
    }
  }
  .term-section{
    .header{
      font-size: $f-18;
      margin-bottom: 15px;
      text-transform: uppercase;
      @include font-weight-style(500, normal);
    }
    p{
      margin-bottom: 15px;
    }
  }
}