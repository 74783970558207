/* ==========================================================================
  Singup left structure
  ========================================================================== */
.signin-wrapper {
  @media screen and (max-width: 992px) {
    min-height: 100%;
    height: 100%;
  }
  .signup-section {
    background: $white;
    @media screen and (max-width: 992px) {
      padding: 40px 15px 15px 15px;
    }
    .signin-container {
      max-width: 100%;
      height: 100vh;
      align-items: center;
      @media screen and (max-width: 992px) {
        height: 100%;
      }
    }
    .login-container {
      padding: 0;
      max-width: 490px;
      margin: 0 auto;
      @media screen and (max-width: 1024px) {
        max-width: 400px;
      }
      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        padding: 0;
        width: 25px;
        height: 25px;
        cursor: pointer !important;
        display: none;
        @media screen and (max-width: 992px) {
          display: block;
        }
        .close-img {
          max-width: 25px;
          height: 25px;
          align-self: center;
          text-align: right;
        }
      }
    }
  }
  .login-banner {
    position: relative;
    width: 50%;
    height: 100vh;
    // background: url('../../images/signin-back.png') no-repeat left center;
    background-color: rgba(0, 0, 0, 0.3);
    background-size: cover;
    @media screen and (max-width: 992px) {
      display: none;
    }
    .black-shadow {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0.57;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), $black);
      background: url(../../images/black-shadow.png) no-repeat bottom center;
      background-size: cover;
    }
    .img-responsive {
      position: absolute;
      bottom: 40px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .form-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 640px) {
      flex-direction: column;
    }
    .full-mb-btn {
      @media screen and (max-width: 992px) {
        min-width: 100%;
      }
    }
    .btn-block {
      width: auto;
    }
    &.center {
      justify-content: center;
    }
    @media screen and (max-width: 640px) {
      &.form-action-mb {
        .btn {
          min-width: 100%;
        }
        .forgot-text {
          margin-top: 15px;
        }
      }
    }
  }
  .btn-text-group {
    margin-top: 14px;
  }
}

.signup-section {
  align-items: center;
  min-height: 100%;
  display: flex;
  .signin-container {
    display: flex;
    margin: 0 auto;
    max-width: 770px;
    width: 100%;
  }
  .logo-container {
    margin-bottom: 18px;
    @media screen and (min-width: $tablet-portrait) {
      margin-bottom: 28px;
    }
    .navbar-brand {
      padding: 0;
      margin: 0;
    }
  }

  .login-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $white;
    text-align: left;
    padding: 50px 50px 79px;
    border-radius: 0px 4px 4px 0px;
    .forgot-text {
      color: $black-base;
      font-size: $f-14;
      @include font-weight-style(400, normal);
      align-items: center;
      float: right;
      span {
        font-size: 14px;
        display: inline-block;
        margin-right: 12px;
      }
    }

    .back-btn {
      font-size: 14px;
      display: block;
      margin-top: 30px;
      color: $grey;
      text-align: center;
      a {
        color: $green;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.forgot-password {
      max-width: 400px;
      margin: 0px auto;
      border: 0;
      padding: 0;
    }
  }
}

.heading-wrap {
  margin-bottom: 50px;
  h1 {
    @include font-weight-style(700, normal);
    font-size: 24px;
    color: $black-base;
    text-transform: uppercase;
    @media screen and (max-width: 640px) {
      font-size: $f-20;
    }
  }
  .caption {
    font-size: $f-14;
    color: $grey-label;
    display: block;
    margin: 16px 0 15px 0;
  }
  .email-label {
    color: $black;
    background: $active-nav-back;
    text-align: center;
    border-radius: 2px;
    min-height: 40px;
    max-width: auto;
    font-size: $f-14;
    display: inline-block;
    margin-top: 25px;
    @include padding(10px, 10px, 10px, 10px);
  }
  .success-img {
    max-width: 60px;
    @media screen and (max-width: 640px) {
      max-width: 50px;
    }
  }
}
