//Common Modal
.modal {
  padding-right: 0 !important;
  background-color: rgba(0, 0, 0, .5);

  .modal-content {
    border-radius: 4px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    border: none;

    .form-group {
      .floating-input {
        label {
          top: 19px;
        }
      }
    }
  }
}

//Form Modal
.form-modal {
  .modal-dialog {
    max-width: 496px;
  }

  .modal-header {
    padding: 29px 29px;
    align-items: center;
    border-color: $light-border;

    .modal-title {
      font-size: 20px;
      color: $black-base;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.5px;
    }

    .close {
      padding: 0;
      margin: 0;
      font-weight: 300;
      opacity: 1;
      line-height: 17px;

      &:hover {
        opacity: 1;
      }

      &:focus {
        outline: none;
      }

      span {
        display: block;
      }
    }
  }

  .modal-body {
    padding: 42px 29px 12px;

    .form-group {
      margin-bottom: 0;

      .floating-input {
        padding-bottom: 30px;
      }
    }

    .form-control {
      height: 52px;
    }

    textarea.form-control {
      padding: 15px 18px;
      height: 132px;
    }
  }

  .modal-footer {
    padding: 30px 29px;
    border-color: $light-border;

    .primary-main {
      min-width: 180px;
      height: 50px;
    }

    .btn-right-gap {
      margin-left: 16px;
    }
  }
}

//Add-location-modal
.add-location-modal {
  .modal-dialog {
    max-width: 496px;
  }
}

//Edit-location-modal
.edit-location-modal {
  .modal-dialog {
    max-width: 496px;
  }
}

//Delete-Modal
.delete-modal {
  .modal-dialog {
    max-width: 420px;
  }

  .modal-body {
    text-align: center;
    padding: 28px 15px 25px;

    .delete-modal-heading {
      font-size: 20px;
      font-weight: bold;
      color: $black-base;
      margin-bottom: 20px;
    }

    .delete-modal-text {
      font-size: 13px;
      line-height: 1.54;
      color: $black-base;
    }
  }

  .modal-footer {
    padding: 0 15px 21px;
    justify-content: center;
    border: none;

    .primary-main {
      min-width: 140px;
      height: 40px;
      font-size: 15px;
    }
  }
}

//Add-plan-modal
.add-plan-modal {
  .modal-dialog {
    max-width: 496px;
  }
}

//Add-partner-modal
.add-partner-modal {
  overflow-x: auto !important;

  .modal-dialog {
    min-width: 600px !important;
  }

  .upload-media-body {
    margin-bottom: 46px;
  }

  .modal-body {
    padding: 30px 29px 0;
  }
}

//Edit-amenities-modal
.edit-amenities-modal {
  .modal-dialog {
    max-width: 496px;
  }

  .upload-media-body {
    margin-bottom: 43px;
  }
}

//Assign-promo-modal
.assign-promo-modal {
  .custom-checkbox {
    margin-bottom: 30px;
  }
}

//Change-password-modal
.change-password-modal {
  .modal-header {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 53px;
    border-bottom: none;
    padding-bottom: 0;

    .close {
      display: none;
    }

    .change-password-heading {
      font-size: 20px;
      color: #28313c;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.5px;
      margin-top: 31px;
    }
  }

  .modal-body {
    padding-top: 48px;
  }

  .modal-footer {
    border-top: none;
    padding-top: 0;
    padding-bottom: 47px;
  }
}

//Payment-summary-modal
.payment-summary-modal {
  overflow-x: auto !important;

  .modal-dialog {
    min-width: 600px !important;
  }

  .modal-body {
    padding: 30px 30px 0 30px;
  }

  .extend-booking-text {
    margin-bottom: 20px;
    font-weight: 500;
  }

  .payment-summary-main-detail {
    margin-bottom: 30px;

    .payment-summary-main-detail-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      strong {
        font-size: 15px;
        font-weight: 500;
      }

      p {
        font-size: 15px;
        font-weight: bold;
      }
    }
  }

  .payment-summary-inner-box {
    box-shadow: 4px 0px 22px -6px #ccc;
    margin-bottom: 30px;
  }

  .payment-summary-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ededed;

    .payment-summary-header-left {
      h4 {
        font-size: 12px;
        color: #6e899d;
        font-weight: 300;
        margin-bottom: 8px;
      }

      strong {
        display: block;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .payment-summary-date {
      color: #6e899d;
      font-size: 14px;
    }
  }

  .payment-summary-mid-box {
    padding: 20px 20px 0 20px;

    .payment-summary-detail-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .payment-summary-detail-box-left {
        h4 {
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 7px;
        }

        strong {
          display: block;
          font-size: 14px;
          color: #6e899d;
          font-weight: 300;
        }
      }

      p {
        font-size: 15px;
        font-weight: bold;
      }
    }
  }

  .payment-summary-footer {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ededed;

    h4 {
      font-weight: 500;
      font-size: 15px;
    }

    p {
      font-weight: 600;
      font-size: 15px;
    }
  }
}