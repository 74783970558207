/*=============================================
*   screen width
=============================================*/

$phone-landscape: 576px;
$tablet-portrait: 768px;
$tablet-landscape: 992px;
$desktop: 1200px;
$desktop-hd: 1400px;

$font-weight: 400;
$font-size-base: 14px;
$line-height-base: 1;


@mixin vendor-prefixes($property, $value) {
  #{$property}: $value;
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
}

//-----------Custom font size will goes here
$f-12:                                          12px;
$f-13:                                          13px;
$f-14:                                          14px;
$f-16:                                          16px;
$f-18:                                          18px;
$f-20:                                          20px;
$f-24:                                          24px;


$primary-color:                                #ffd938;
$white:                                        #FFFFFF;
$black:                                        #000000;
$black-base:                                   #28313c;
$red:                                          #fe2842;
$success:                                      #4eab50;
$alert-back:                                   #f3faf2;
$item-hover:                                   #f74a4a;
$grey-label:                                   #646464;
$gray-97:                                      #979797;
$active-nav-back:                              #fffae8;
$light-border:                                 #ededed;
$list-item-back:                               #eaeaea;
$primary-border:                               #e6e6e6;
$light-grey-bg:                                #f8f8f8;
$secondary-btn:                                #b9b9b9;
$white-btn-text:                               #2e3543;
$file-upload-border:                           #bcbcbc;
$file-upload-back:                             #fafafa;
$dark-heading:                                 #1f2532;
$grey-border:                                  #e1e1e1;
$green:                                        #4eab50;
$grey :                                        #707070;
$btn-link-background:                          #FFFFFF !default;
$btn-link-text:                                #4880ff !default;
$btn-link-border:                              #4880ff !default;
$input-background:                             #FFFFFF;
$input-text:                                   #000000;
$error-color:                                  #fe2842;
$green1:                                       #67c07a;
$blue1:                                        #50c1b5;
$danger:                                       #ffeff1;
$toggle-back:                                  #cee9d6;
$custom-dropdown-color:                        #969696;
$custom-radio:                                 #526879;
$radio-border:                                 #9fb3c2;
$green-light:                                  #1fa561;


