.btn {
  @include font-weight-style(700, normal);
  font-size: $f-16;
  border-radius: 4px;
  height: 50px;
  @include padding(5px, 20px, 5px, 20px);
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow:none;
  }
  &.btn-block {
    width: 100%;
  }
  &.medium {
    width: 180px;
  }
  &.w-240 {
    width: 240px;
  }
  &.w-100 {
    width: 100%;
  }
  &.primary-btn {
    min-width: 240px;
    color: $black-base;
    border:0;
    border-color: transparent;
    @extend %all-animation;
    background: $primary-color; /* Old browsers */
    @include font-weight-style(700, normal);
    letter-spacing: 0.25px;
    padding: 0;
    &:hover,
    &:focus,
    &:active {
      border-color: transparent;
    }
    &.disabled, &:disabled {
      opacity: 0.7;
    }
  }
  &.secondary-btn {
    background-color: $secondary-btn;
    color: $white;
    &.disabled, &:disabled {
      opacity: 0.7;
    }
  }
  &.link{
    min-width: 0;
    color: $black-base;
    background: transparent;
    border-color: transparent;
    @extend %all-animation;
    @include padding(5px, 5px, 5px, 5px);
    &:hover,
    &:focus,
    &:active{
        color: $black-base;
        background: transparent;
        border-color: transparent;
    }
    &.disabled, &:disabled {
      color: rgba($btn-link-text, 0.5);
      background: $btn-link-background;
      border-color: $btn-link-border;
    }
  }
}

.center-btn {
  margin:  0 auto;
  text-align: center;
  .btn+.btn {
      margin-left: 10px;
  }
}

//White-big-btn
.white-small-btn {
  min-width: 138px;
  height: 50px;
  border-radius: 2px;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  font-size: 14px;
  color: $white-btn-text;
  font-weight: 400;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  img {
    margin-right: 16px;
  }
  &:hover {
    background-color: $white !important;
    color: $white-btn-text !important;
  }
  &:focus {
    box-shadow: none !important;
    outline: none !important;
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1) !important;
  }
  &.white-big-btn {
    min-width: 158px;
  }
}

//Edit btn
.edit-btn {
  img {
    margin-right: 8px;
  }
}