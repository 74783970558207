@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?z5qx8u');
  src:  url('../../fonts/icomoon.eot?z5qx8u#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?z5qx8u') format('truetype'),
    url('../../fonts/icomoon.woff?z5qx8u') format('woff'),
    url('../../fonts/icomoon.svg?z5qx8u#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dashboard-icon:before {
  content: "\e90f";
}
.icon-partners-api-icon:before {
  content: "\e910";
}
.icon-down-arrow:before {
  content: "\e90e";
}
.icon-right-arrow:before {
  content: "\e90d";
}
.icon-cross:before {
  content: "\e90c";
}
.icon-change-password:before {
  content: "\e90a";
}
.icon-logout-icon:before {
  content: "\e90b";
}
.icon-booking-icn:before {
  content: "\e900";
}
.icon-customer-icn:before {
  content: "\e901";
}
.icon-dashboard-icn:before {
  content: "\e902";
}
.icon-btlinzbox-icn:before {
  content: "\e903";
}
.icon-partners-icn:before {
  content: "\e904";
}
.icon-payments-icn:before {
  content: "\e905";
}
.icon-places-icn:before {
  content: "\e906";
}
.icon-pricing-icn:before {
  content: "\e907";
}
.icon-qr-code-icn:before {
  content: "\e908";
}
.icon-sponsor-icn:before {
  content: "\e909";
}
