//header
header {
  height: 65px;
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  @media (max-width: 767px) {
    position: static;
    width: 100%;
    min-width: 1250px;
  }

  .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
  }
}