//Common Css
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "roboto", sans-sarif;
  background-color: $light-grey-bg;
}
a {
  transition: 0.5s;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.container-fluid {
  padding: 0 30px;
}

//up-down-combine
.up-down-combine {
  span {
    display: block;
    line-height: 0;
    cursor: pointer;
    &:first-child {
      margin-bottom: 3px;
    }
  }
}

//Custom-breadcrumb
.custom-breadcrumb {
  .breadcrumb {
    margin-bottom: 0;
    padding: 0;
    background: none;
    align-items: center;
    .breadcrumb-item {
      text-transform: uppercase;
      font-size: 14px;
      color: $black-base;
      a {
        color: $gray-97;
      }
      & + .breadcrumb-item {
        display: flex;
        align-items: center;
        padding-left: 0;
        &:before {
          content: "\e90d";
          font-size: 24px;
          padding-right: 0;
          font-family: "icomoon", sans-serif;
        }
      }
    }
  }
}

//Pagination
.pagination {
  a {
    font-weight: 300;
    font-size: 12px;
    padding: 10.5px 14px;
    border: 1px solid $primary-border;
    display: block;
    color: $grey-label !important;
    border-right: none;
    cursor: pointer;
    &.active {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $black-base !important;
    }
    &:first-child {
      border-radius: 3px 0 0 3px;
    }
    &:last-child {
      border-radius: 0 3px 3px 0;
      border-right: 1px solid $primary-border;
    }
  }
}

p {
  @include font-weight-style(400, normal);
  word-wrap: break-word;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

select::-ms-expand {
  display: none;
}

input::-ms-clear,
::-ms-reveal {
  display: none;
}

:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

textarea {
  resize: none;
}

.not-active {
  pointer-events: none;
  cursor: default;
}

img[src=""] {
  display: none;
}

.cls {
  clear: both;
  margin: 0;
  padding: 0;
}

::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.mgt10 {
  margin-top: 10px;
}

.mgt15 {
  margin-top: 15px;
}

.mgt20 {
  margin-top: 20px;
}

.mgt25 {
  margin-top: 25px;
}

.mgt30 {
  margin-top: 30px;
}

.mgt40 {
  margin-top: 40px;
}

.mgb10 {
  margin-bottom: 10px;
}

.mgb15 {
  margin-bottom: 15px;
}

.mgb20 {
  margin-bottom: 20px !important;
}

.mgb30 {
  margin-bottom: 30px !important;
}

.mgb0 {
  margin-bottom: 0 !important;
}

.mgtb20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.pdt {
  padding-top: 0 !important;
}

.pdb0 {
  padding-bottom: 0 !important;
}

.pdr0 {
  padding-right: 0 !important;
}

.word-break {
  word-break: break-word;
}

.default-cursor {
  cursor: default;
}

.cursor {
  cursor: pointer;
}

.pd {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.pdl {
  padding-left: 0 !important;
}

.pdr {
  padding-right: 0 !important;
}

.pdb40 {
  padding-bottom: 40px;
}

.pdb20 {
  padding-bottom: 20px !important;
}

.mb0 {
  margin-bottom: 0;
}

.wid100 {
  float: left;
  width: 100%;
}
.extra-bold {
  @include font-weight-style(800, normal);
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.divider {
  width: 100%;
  height: 1px;
  background: $white;
  margin: 35px 0 30px;
  opacity: 0.15;
}

%all-animation {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

%base-gradient {
  color: $white;
  background: $green1;
  background: -moz-linear-gradient(151deg, $green1 0%, $blue1 100%);
  background: -webkit-linear-gradient(151deg, $green1 0%, $blue1 100%);
  background: linear-gradient(151deg, $green1 0%, $blue1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$green1', endColorstr='$blue1', GradientType=0);
}

//Remove spinners from input number feild
/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//Custom-color-picker
.custom-color-picker {
  position: absolute;
  z-index: 99999999;
  bottom: 70px;
}

//Custom-dropdown-with-values
.custom-dropdown-with-values {
  &.label-heightlight {
    .css-yk16xz-control {
      border: 2px solid $primary-color;
      &:hover {
        border: 2px solid $primary-color;
      }
    }
  }
  &.edit-floating-input {
    .css-1pahdxg-control {
      border: 1px solid $grey-border;
      &:hover {
        border: 1px solid $grey-border;
      }
    }
  }
  .css-yk16xz-control {
    border: 1px solid $grey-border;
    font-size: 14px;
    min-height: 52px;
    position: relative;
    &:hover {
      border: 1px solid $grey-border;
    }
    .css-1wa3eu0-placeholder {
      position: absolute;
      transform: none;
      top: 18px;
      margin: 0;
      padding: 0;
      display: none;
    }
  }
  .css-1hwfws3 {
    padding: 0.375rem 1.125rem;
    position: static;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-1pahdxg-control {
    border: 2px solid $primary-color;
    font-size: 14px;
    min-height: 52px;
    box-shadow: none;
    &:hover {
      border: 2px solid $primary-color;
    }
    .css-1wa3eu0-placeholder {
      margin: 0;
      top: 18px;
      transform: none;
      display: none;
    }
  }
  .css-2613qy-menu {
    display: block !important;
  }
  .css-1gtu0rj-indicatorsContainer {
    display: none !important;
  }
  .custom-dropdown-with-values {
    .css-1hwfws3 {
      padding: 0;
    }
  }
  .css-1rhbuit-multiValue {
    background-color: $list-item-back;
    height: 30px;
    margin: 0 2.5px;
    align-items: center;
    position: relative;
    .css-12jo7m5 {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      color: $black-base;
      padding: 0 24px 0 8px;
    }
    .css-xb97g8 {
      padding: 0;
      &:after {
        font-family: "icomoon";
        content: "\e90c";
        position: absolute;
        top: 4px;
        right: 0;
        cursor: pointer;
        font-size: 24px;
        color: $custom-dropdown-color;
      }
      svg {
        display: none;
      }
    }
  }
  &.active-label {
    .css-1wa3eu0-placeholder {
      display: none;
      top: -8px !important;
      bottom: inherit;
      background: $white;
      font-size: 14px;
      color: $primary-color;
      padding: 0 4px;
      margin: 0;
      transform: none;
      transition: all 500ms ease 0s;
      left: 15px;
    }
    .css-yk16xz-control {
      border: 2px solid $primary-color;
    }
    .css-1pahdxg-control {
      border: 2px solid $primary-color;
    }
  }
  .css-1uccc91-singleValue {
    transform: none;
    top: 18px;
    margin: 0;
  }
  .css-tlfecz-indicatorContainer {
    &:after {
      font-family: "icomoon";
      content: "\e90e";
      position: absolute;
      top: 13px;
      right: 10px;
      font-size: 26px;
      color: $white-btn-text;
    }
    svg {
      display: none !important;
    }
  }
  .css-1gtu0rj-indicatorContainer {
    &:after {
      font-family: "icomoon";
      content: "\e90e";
      position: absolute;
      top: 13px;
      right: 10px;
      font-size: 26px;
      color: $white-btn-text;
    }
    svg {
      display: none !important;
    }
  }
}

//Primary-btn-with-a
.primary-btn-with-a {
  line-height: 40px !important;
}

//Empty-text-wrapper
.empty-text-wrapper {
  position: absolute;
  left: 30px;
  right: 30px;
  .empty-text {
    position: absolute;
    text-align: center;
    left: 30px;
    right: 30px;
    top: 29px;
    font-size: 14px;
    color: $black-base;
    &.dasboard-page {
      text-align: left;
    }
  }
}

//Black-wrapper
.black-wrapper {
  background-color: transparent;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}

//Calendar-form-group
.calendar-form-group {
  .form-control {
    background-image: url("../../images/svg/calendar.svg");
    background-repeat: no-repeat;
    background-position: 97.5% 50%;
  }
}

//Custom-radio
.custom-radio {
  position: relative;
  input {
    display: none;
    &:checked {
      & + span {
        &:after {
          display: block;
          background-color: $black-base;
        }
        &:before {
          border-color: $black-base;
        }
      }
    }
  }
  span {
    font-size: 16px;
    color: $custom-radio;
    padding-left: 30px;
    cursor: pointer;
    &:before {
      content: "";
      height: 18px;
      width: 18px;
      position: absolute;
      left: 0;
      top: -2px;
      border-radius: 50%;
      border: 2px solid $radio-border;
      background-color: $white;
    }
    &:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 3px;
      height: 8px;
      width: 8px;
      background-color: $black-base;
      border-radius: 50%;
      display: none;
    }
  }
}

//Custom-date-picker
.react-datepicker-wrapper {
  display: block !important;
}
.react-datepicker-popper {
  z-index: 999999 !important;
}

//Custom-dropdown-with-arrow
.custom-dropdown-with-arrow {
  .dropdown-menu {
    position: relative;
    &:after {
      content: " ";
      position: absolute;
      right: 26px;
      top: -9px;
      border-top: none;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 8px solid white;
      box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

//lock-toggle
.lock-toggle .switch {
  position: relative;
  width: 126px;
  height: 35px;
  border-radius: 17.5px;
  cursor: pointer;
}
.lock-toggle .switch-label {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  background-color: $toggle-back;
  position: relative;
  display: block;
  height: inherit;
  font-size: 12px;
  border-radius: inherit;
  font-weight: bold;
  color: $black-base;
}
.lock-toggle .switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}
.lock-toggle .switch-label:before {
  content: attr(data-off);
  right: 15px;
}
.lock-toggle .switch-input:checked ~ .switch-handle {
  left: 87px;
}
.lock-toggle .switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.lock-toggle .switch-input:checked ~ .switch-label {
  background-color: $toggle-back;
}
.lock-toggle .switch-label:after {
  content: attr(data-on);
  left: 15px;
  color: $black-base;
  opacity: 0;
}
.lock-toggle .switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.lock-toggle .switch-input:checked ~ .switch-label:after {
  opacity: 1;
}
.lock-toggle .switch-handle {
  position: absolute;
  top: -2px;
  left: -5px;
  width: 40px;
  height: 40px;
  background-image: url("../../images/svg/circle-lock.png");
  background-repeat: no-repeat;
}
.lock-toggle .switch-label,
.switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

//Loader
.loader {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 80px;
    width: 80px;
  }
}
