//Custom Alert
.alert-main {
  right: 0;
  left: 0;
  top: 40px;
  position: fixed;
  z-index: 99999;
  display: flex;
  justify-content: center;
  -webkit-transform: translate3d(0%, 0, 0) !important;
  -ms-transform: translate3d(0%, 0, 0) !important;
  -o-transform: translate3d(0%, 0, 0) !important;
  transform: translate3d(0%, 0, 0) !important;
  opacity: 0;
  transition: all 1s ease 0.5s !important;
  -webkit-transition: all 1s ease 0.5s !important;
  &.show {
    opacity: 1;
  }
}

//alert
.alert {
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-bottom: 0;
  min-width: 370px;
  height: 50px;
  .alert-text {
    font-size: 14px;
    margin-right: 30px;
  }
  .close {
    font-size: 10px;
    opacity: 1;
    text-shadow: none;
    text-align: center;
    cursor: pointer;
    position: absolute;
    right: 20px;
    &:hover {
      opacity: 1 !important;
    }
  }
  .alert-img {
    margin-right: 10px;
  }
}

//alert-success
.alert-success {
  color: $success;
  background-color: $alert-back;
  border-color: $alert-back;
  .close {
    color: $success;
  }
}

//alert-success
.alert-danger {
  color: $red;
  background-color: $danger;
  border-color: $danger;
  .close {
    color: $red;
  }
}

//Custom Toastify
.Toastify__toast-container--top-center {
  top: 40px !important;
  width: 100% !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  left: 0 !important;
  margin-left: 0 !important;
}
.Toastify__toast {
  padding: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  font-family: 'roboto', sans-serif !important;
  min-height: auto !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.08) !important;
}
.Toastify__close-button {
  display: none;
}