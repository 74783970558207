// Custom-dropdown
.custom-dropdown {
  .dropdown-toggle {
    background: none !important;
    border: none !important;
    color: $black !important;
    &:focus {
      box-shadow: none !important;
    }
  }
  button {
    background: none;
    padding: 0;
    border: none;
    font-size: 14px;
    color: $black;
    display: flex;
    align-items: center;
    font-weight: 400;
    height: auto;
    &:hover {
      background: none;
      border: none;
      color: $black;
    }
    &:focus {
      outline: none !important;
      background: none !important;
      border: none !important;
      color: $black !important;
    }
    &:after {
      display: none;
    }
  }
  .dropdown-menu {
    min-width: 250px;
    border-radius: 3px;
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
    padding: 0;
    border: none;
    margin-top: 30px;
    right: 0 !important;
    left: auto !important;
    transform: translate3d(0px, 23px, 0px) !important;
    .dropdown-item {
      font-size: 14px;
      height: 54px;
      color: $black-base;
      display: flex;
      align-items: center;
      padding: 0 0 0 16px;
      border-bottom: 1px solid $light-border;
      &.dropdown-item-with-color {
        color: $item-hover;
      }
      i {
        margin-right: 6px;
      }
      &:last-child {
        border: none;
      }
      &:hover {
        background-color: $white;
      }
    }
  }
}

//Search-form-group
.form-group {
  &.search-form-group {
    position: relative;
    margin-bottom: 0;
    .form-control {
      height: 40px;
      width: 200px;
      padding-left: 39px;
      border-color: $primary-border;
      &:focus {
        border-color: $primary-color;
      }
    }
    .search-icon {
      position: absolute;
      top: 1px;
      left: 1px;
      background-color: $white;
      border-radius: 4px 0 0 4px;
      padding: 5.2px 7px;
      cursor: pointer;
    }
  }
}

//Inner-table-dropdown
.custom-small-dropdown {
  button {
    font-size: 13px;
    .arrow-down {
      margin-left: 4px;
    }
    &.active-toggle {
      color: $success !important;
    }
    &.inactive-toggle {
      color: $item-hover !important;
    }
  }
  .dropdown-menu {
    min-width: 150px;
    margin-top: 5px;
    transform: translate3d(-65px, 26px, 0px) !important;
    .dropdown-item {
      font-size: 13px;
      height: 51px;
    }
  }
}

//Custom-upload
.custom-upload {
  input {
    display: none;
  }
  .file-upload-label {
    width: 120px;
    height: 120px;
    border-radius: 4px;
    border: solid 1.5px $primary-color;
    background-color: $active-nav-back;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }
  .file-upload-text {
    font-size: 12px;
    font-weight: 500;
    color: $black-base;
    margin-top: 13px;
  }
}

//Custom-checkbox
.custom-checkbox {
  label {
    cursor: pointer;
    input {
      display: none;
      &:checked {
        & + span {
          &:after {
            display: block;
          }
          &:before {
            border-color: $primary-color;
            background-color: $primary-color;
          }
        }
      }
    }
    span {
      position: relative;
      font-size: 14px;
      color: $black-base;
      padding-left: 29px;
      padding-top: 2px;
      &:before {
        position: absolute;
        content: '';
        height: 19px;
        width: 19px;
        left: 0;
        top: 0;
        border: 2px solid $black;
        border-radius: 2px;
        background-color: $white;
      }
      &:after {
        display: none;
        content: '';
        position: absolute;
        left: 7px;
        top: 3.5px;
        width: 6px;
        height: 10px;
        border: 1px solid $black;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}

.form-control {
  background: $white;
  border: 1px solid $grey-border;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  color: $input-text;
  padding: .375rem 1.125rem;
  @include font-weight-style(400, normal);
  font-size: $f-14;
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: $grey-label;
    opacity: 1;
  }
  &:focus {
    color: $input-text;
    background-color: $input-background;
    border-color: $primary-color;
    outline: 0;
    box-shadow: none;
  }
  .was-validated .form-control:invalid,
    &.is-invalid {
      border-width: 2px;
      border-color: $error-color;
      background-image: none;
      &:focus {
        border-color: $error-color;
        box-shadow: none;
      }
      ~ .error,
      ~ .invalid-tooltip {
        display: flex;
      }
    }
    &.form-control:-webkit-autofill,
    &.form-control:-webkit-autofill:hover,
    &.form-control:-webkit-autofill:focus,
    &.form-control:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
      -moz-box-shadow: 0 0 0px 1000px $white inset !important;
      box-shadow: 0 0 0px 1000px $white inset !important;
      -webkit-text-fill-color: $input-text !important;
    }
    &.form-control {
      filter: none;
    }
}

.form-row {
  margin-right: -10px;
  margin-left: -10px;
  >.col, >[class*=col-]{
    padding-right: 10px;
    padding-left: 10px;
  }
}

/* ==========================================================================
  floating label
  ========================================================================== */
  .form-group {
    position: relative;
    margin-bottom: 4px;
    label {
      color: $grey;
      font-size: 14px;
      position: absolute;
      top: 17px;
      left: 15px;
      transition: all 500ms ease 0s;
      padding: 0 4px;
      pointer-events: none;
      font-family: 'roboto', sans-serif;
      z-index: 1;
    }
    &.move.active {
      .form-control {
        border-color: $primary-color;
      }
    }
    .floating-input {
      padding-bottom: 36px;
      .password-input{
        padding-right: 70px;
      }
      &:focus {
        border: 1px solid $primary-color;
      }
      &.label-heightlight,
      &.active {
        label {
          top: -8px !important;
          bottom: inherit;
          background: $white;
          font-size: $f-14;
          color: $primary-color;
          @include font-weight-style(700, normal);
        }
        .form-control{
          border: 2px solid $primary-color;
        }
      }
    }
    .error {
      @include font-weight-style(400, normal);
      font-size: $f-13;
      color: $error-color;
      margin-top: -34px;
      float: left;
      line-height: 14px;
    }
    .input-icon{
      position: absolute;
      right: 15px;
      top: 13px;
      cursor: pointer;
      max-width: 25px;
    }

  }

//floating-input
.floating-input {
  &.edit-floating-input {
    label {
      top: -6px !important;
      background-color: $white;
    }
    .form-control {
      &:focus {
        border: 1px solid $primary-color;
      }
    }
  }
}

.form-control.form-control:-webkit-autofill, 
.form-control.form-control:-webkit-autofill:hover {
  -webkit-text-fill-color: #fff !important;
}

.label-heightlight .form-control.form-control:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}
