//main-container
.main-container {
  &.server-error {
    height: 100vh;
    @media (max-width: 640px) {
      height: calc(100% - 60px);
      margin-top: 70px;
    }
  }
}

//Page-not-found-container
.page-not-found-container {
  max-width: 560px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px 15px 15px 15px;
  @media (max-width: 640px) {
    display: block;
  }
  h3 {
    font-size: 21.6px;
    color: $dark-heading;
    letter-spacing: 5.4px;
    text-transform: uppercase;
    margin-bottom: 30px;
    @include font-weight-style(400, normal);
  }
  .server-error {
    img {
      @media (max-width: 640px) {
        max-width: 200px;
      }
    }
  }
  p {
    font-size: $f-18;
    color: $black;
    @include font-weight-style(400, normal);
    line-height: 32.4px;
    margin:30px 0 46px 0;
  }
  .btn {
    &.primary-btn {
      min-width: 270px;
      height: 48px;
    }
  }
}
